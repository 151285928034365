<template>
  <div class="grid">
    <CrudTable ref="shipment" :entities="entities"
      :meta="metaTable"
      @page="onPage"
      @tableAction="onTableAction"
      @itemAction="onItemAction"
      @cell-edit-complete="onCellEditComplete"/>
  </div>
</template>
<script>
import CrudTable from '@/pages/common/CrudTable';
import PackageService from '@/service/PackageService';
import AuthService from '@/service/AuthService';
import adjustment from '@/utils/adjustment';
import formatter from '@/utils/formatter';
import {converter} from '@/utils/common';
import { Consts } from '@/utils/consts';
export default {
  components: {CrudTable},
  data() {
    return {
      entities: [],
      selectedEntity: null,
      currentUser: null,
      metaTable : {
        entityName: "package",
        expander: true,
        searchable: true,
        editMode: "cell",
        lazy: true,
        paginator:{
          enable: true,
          rows: 10,
          rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
          currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} packages",
        },
        filter: {
          filterDisplay: "menu",
          globalFilterFields: ['shipmentCode','awbCode']
        },
        columns: [
          {
            name: "shippedDate",
            label: "package.shippedDate",
            headerStyle: "width:8%; min-width:8rem;",
            filter: true,
            dataType: "date",
            formatter: formatter.formatDate
          },
          {
            name: "storedDate",
            label: "package.storedDate",
            headerStyle: "width:8%; min-width:8rem;",
            filter: true,
            dataType: "date",
            formatter: formatter.formatDate
          },
          {
            name: "packageCode",
            headerStyle: "width:8%; min-width:8rem;",
            filter: true,
            editor: {
              enable: true
            }
          },
          {
            name: "agentCode",
            label:"package.agentCode",
            headerStyle: "width:5%; min-width:5rem;",
            filter: true,
            condition:"user.orgType=='owner'"
          },
          {
            name: "shipmentCode",
            headerStyle: "width:10%; min-width:10rem;",
            filter: true
          },
          {
            name: "awbCode",
            headerStyle: "width:10%; min-width:10rem;",
            filter: true
          },
          {
            name: "weightLbs",
            headerStyle: "width:3%; min-width:5rem;",
            filter: true,
            dataType:"numeric",
          },
          {
            name: "weightKg",
            headerStyle: "width:3%; min-width:5rem;",
            filter: true,
            dataType:"numeric",
          },
          {
            name: "actualWeightLbs",
            headerStyle: "width:3%; min-width:6rem;",
            filter: true,
            dataType:"numeric"
          },
          {
            name: "actualWeightKg",
            headerStyle: "width:3%; min-width:6rem;",
            filter: true,
            dataType:"numeric",
            editor: {
              enable: true,
              style:"width: 120px"
            }
          },
          {
            name: "storeStatus",
            headerStyle: "width:10%; min-width:10rem;",
            filter: true,
            formatter: this.storeStatusFormatter
          }
        ],
        tableActions: [
          {
            name: "filterclear",
            icon: "pi pi-filter-slash",
            style: "p-button-outlined mr-2"
          }
        ],
        itemActions: [
          {
            name: "edit",
            icon: "pi pi-pencil",
            style: "p-button-rounded mr-2",
            condition: "mode != 'edit'"
          }
        ]
      }
    }
  },
  created() {
    this.service = PackageService;
	},
  mounted() {
    this.currentUser = AuthService.currentUser;
    PackageService.getArrivedPackages().then(data => {
      if(Array.isArray(data.content)) {
        data.content.forEach(item => adjustment.adjustPackage(item))
        this.entities = data.content;
        this.metaTable.paginator.totalRecords = data.totalElements;
      }
    });
  },
  methods: {
    formatDate(jsDate) {
      return formatter.formatDate(jsDate);
    },
    tokg(lbs) {
      return converter.tokg(lbs);
    },
    tolbs(kg) {
      return converter.tolbs(kg);
    },
    storeStatusFormatter(status) {
      return isNaN(status) ? "" : this.$t("package.store_status_" + status);
    },
    onPage(event) {
      PackageService.getArrivedPackages({
        page:event.page,
        size:event.rows
      }).then(data => {
        data.content.forEach(item => adjustment.adjustPackage(item))
        this.entities = data.content;
        this.metaTable.paginator.totalRecords = data.totalElements;
      });
    },
    onTableAction(sAction) {
      console.log(sAction)
    },
    onItemAction(sAction, entity, index) {
      if(sAction == 'edit') {
        this.$router.push({ name: 'user_package_infos', params: {id: entity.id} });
      }
      console.log(index);
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      switch (field) {
          case 'actualWeightKg':
              if (this.isPositiveInteger(newValue)) {
                  data[field] = newValue;
                  this.service.updateField(data.id, field, newValue);
              } else {
                  event.preventDefault();
              }
          break;

          default:
              if (newValue.trim().length > 0) {
                  data[field] = newValue;
                  this.service.updateField(data.id, field, newValue);
              } else {
                  event.preventDefault();
              }
          break;
      }
    },
    isPositiveInteger(val) {
        let str = String(val);
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    },
  }
}
</script>
